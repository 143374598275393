<template>
  <div class="w-100">
    <v-data-table
      :headers="headers"
      :header-props="headerProps"
      :items="items"
      :search="search"
      hide-default-header
      :hide-default-footer="!footer"
      v-bind="$attrs"
      v-on="$listeners"
      :footer-props="{
        'items-per-page-options': [itemsPerPage, 20, 25, 30, 50],
        'items-per-page-text': $t('table_rows_per_page')
      }"
      :items-per-page="itemsPerPage"
      :pageCount="numberOfPages"
      :server-items-length="totalRecords"
      @click:row="handleClick"
      :disable-pagination="disablePagination"
      :no-data-text="$t('table_no_data_text')"
      :loading-text="$t('loading_table_items')"
      mobile-breakpoint="275"
      :page.sync="currentPage"
      :item-class="getItemClass"
    >
      <template v-slot:header="{ props, on }">
        <thead>
          <tr>
            <th
              v-for="header in props.headers"
              :aria-label="header.text"
              role="columnheader"
              :class="[
                getSortableClass(header.sortable),
                getAlignClass(header.align),
                header.class ? header.class : '',
                !props.options.sortDesc[0] &&
                props.options.sortBy[0] === header.value
                  ? 'desc'
                  : 'asc'
              ]"
              :key="header.value"
              @click="
                items.length > 1 && header.sortable ? on.sort(header.value) : ''
              "
              :width="header.width ? header.width : ''"
            >
              <span v-if="header.helpIcon">
                {{ header.text }}
                <v-tooltip color="white" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="#fff" size="20">
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <span class="text-333" v-html="header.helpText"></span>
                </v-tooltip>
              </span>
              <span v-else>
                {{ header.text }}
              </span>
              <v-icon
                size="15"
                class="swap-sorting-icon"
                color="#fff"
                v-if="header.sortable"
                >$SwapVertical</v-icon
              >
            </th>
          </tr>
        </thead>
      </template>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>
    </v-data-table>
    <div
      class="text-center mt-2 pagination-main-outer"
      v-if="!disablePagination && totalRecords > 0"
    >
      <v-pagination
        class="elevation-0"
        v-model="currentPage"
        :length="numberOfPages"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    footer: {
      type: Boolean,
      default: false,
      required: false
    },
    headers: {
      type: Array,
      default: () => [],
      required: true
    },
    headerProps: {
      type: Object,
      default: () => {},
      required: false
    },
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    search: {
      type: String,
      default: "",
      required: false
    },
    numberOfPages: {
      type: Number,
      default: 0,
      required: false
    },
    totalRecords: {
      type: Number,
      default: 0,
      required: false
    },
    itemsPerPage: {
      type: Number,
      default: 15,
      required: false
    },
    itemClass: {
      type: [Function, String],
      default: "",
      required: false
    },
    page: {
      type: Number,
      default: 1,
      required: false
    },
    disablePagination: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      currentPage: 1
    };
  },
  methods: {
    getItemClass() {
      return this.itemClass ?? "OO";
    },
    pageUpdateFunction(newPageNumber) {
      this.currentPage = newPageNumber;
    },
    handleClick(value) {
      this.$emit("row-clicked", value);
    },
    getSortableClass(sortable) {
      return sortable !== false ? "sortable" : "";
    },
    getAlignClass(align) {
      return align !== null ? "text-" + align : "text-left";
    }
  }
};
</script>

<style lang="scss" scope>
.pagination-main-outer {
  max-width: 308px;
  margin: auto;
}
.pagination-main-outer .v-pagination__more {
  align-items: center !important;
}
</style>
